export function Underlay() {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        padding: 40,
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        pointerEvents: "none",
      }}>
      <div style={{ width: "100%", padding: 0, display: "inline-flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <p
          style={{
            fontFamily: "'Zen Kaku Gothic Antique', sans-serif",
            flex: "1 1 0%",
            height: 30,
            fontSize: 30,
            color: "red",
            fontWeight: "700",
            opacity: 0.7,
            letterSpacing: 0,
          }}>
          ELZERMAN <br/>DIGITAL PRODUCTS
        </p>

         </div>

      <div
        className="full"
        style={{
          fontFamily: "'Zen Kaku Gothic Antique', sans-serif",
          width: "100%",
          flex: "1 1 0%",
          padding: 0,
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "center",
        }}>
       </div>
      <div style={{ height: 60 }} />

    </div>
  )
}

export function Overlay() {
  return (
    <div style={{ position: "absolute", bottom: 40, right: 40 }}>
      <p style={{
            fontFamily: "'Zen Kaku Gothic Antique', sans-serif",
            flex: "1 1 0%",
            height: 30,
            fontSize: 16,
            fontWeight: "700",
            lineHeight: "30px",
            color: "red",
            opacity: 0.8,
            letterSpacing: 0,
          }}>
        CONTACT ME : RUBEN @ ELZERMAN . ORG
      </p>
    </div>
  )
}
