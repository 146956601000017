import * as THREE from "three"
import { useEffect } from "react"
import { Canvas, useFrame, useThree } from "@react-three/fiber"
import { Physics, usePlane, useCompoundBody, useSphere } from "@react-three/cannon"
import { CameraShake, Environment, useGLTF } from "@react-three/drei"
import { EffectComposer, SSAO, SSAA, Glitch } from "@react-three/postprocessing"

const bubleMaterial = new THREE.MeshLambertMaterial({ color: "red", emissive: "white" })
const sphereGeometry = new THREE.SphereGeometry(1, 28, 28)
const bubles = [...Array(40)].map(() => ({ args: [1.1, 0.5, 0.7, 0.6, 0.8][Math.floor(Math.random() * 5)], mass: 1, angularDamping: 0.5, linearDamping: 0.25 }))

function Buble({ vec = new THREE.Vector3(), ...props }) {
  const [ref, api] = useCompoundBody(() => ({
    ...props,
    shapes: [
      { type: "Box", position: [0, 0, 1.2 * props.args], args: new THREE.Vector3().setScalar(props.args * 0.4).toArray() },
      { type: "Sphere", args: props.args },
    ],
  }))
  useEffect(() => api.position.subscribe((p) => api.applyForce(vec.set(...p).normalize().multiplyScalar(-props.args * 65).toArray(), [0, 0, 0])), [api]) // prettier-ignore
  return (
    <group ref={ref} dispose={null}>
      <mesh castShadow receiveShadow scale={props.args} geometry={sphereGeometry} material={bubleMaterial} />
    </group>
  )
}

function Collisions() {
  const viewport = useThree((state) => state.viewport)
  usePlane(() => ({ position: [0, 0, 0], rotation: [0, 0, 0] }))
  usePlane(() => ({ position: [0, 0, 8], rotation: [0, -Math.PI, 0] }))
  usePlane(() => ({ position: [0, -4, 0], rotation: [-Math.PI / 2, 0, 0] }))
  usePlane(() => ({ position: [0, 4, 0], rotation: [Math.PI / 2, 0, 0] }))
  const [, api] = useSphere(() => ({ type: "Kinematic", args: 2 }))
  return useFrame((state) => api.position.set((state.mouse.x * viewport.width) / 2, (state.mouse.y * viewport.height) / 2, 2.5))
}

export const App = () => (
  <Canvas
    shadows
    dpr={1.5}
    gl={{ alpha: true, stencil: false, depth: false, antialias: false }}
    camera={{ position: [0, 0, 20], fov: 15, near: 10, far: 100 }}
    onCreated={(state) => (state.gl.toneMappingExposure = 2.5)}>
    <ambientLight intensity={0.5} />
    <spotLight position={[20, 20, 25]} penumbra={1} angle={2} color="black" castShadow shadow-mapSize={[512, 512]} />
    <directionalLight position={[0, 5, -4]} intensity={10} />
    <directionalLight position={[0, -15, -0]} intensity={10} color="red" />
    <Physics gravity={[0, 0, 0]} iterations={1} broadphase="SAP">
      <Collisions />
      {bubles.map((props, i) => <Buble key={i} {...props} />) /* prettier-ignore */}
    </Physics>
    {/* <Environment files="/adamsbridge.hdr" /> */}
    <EffectComposer multisampling={0}>
      {/* <Glitch /> */}
      <SSAO samples={11} radius={200} intensity={100} luminanceInfluence={.1} color="red" />
    </EffectComposer>
    <CameraShake yawFrequency={0.05} pitchFrequency={0.052} rollFrequency={0.05} />
  </Canvas>
)
